import { useEffect, useState } from 'react';
import {
  firestore,
  collection,
  doc,
  updateDoc,
  onSnapshot,
  setDoc,
  auth,
} from './firebase';
import { getIdsAndData, getCustomUid } from './utilities';
import { initialSkiData, initialSnowboardData } from './static-data';
import AppContainer from './components/AppContainer';
import Header from './components/Header';
import Footer from './components/Footer';
import SignInOrSignUp from './components/SignInOrSignUp';
import SignOut from './components/SignOut';
import MasteryGrid from './components/MasteryGrid';
import CapitalisedHeading from './components/CapitalisedHeading';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import './App.css';

const dataSubscription = (setData, customUid) => {
  if (customUid) {
    const unsubscribe = onSnapshot(
      collection(firestore, 'users', customUid, 'disciplines'),
      (snapshot) => {
        const data = snapshot.docs?.map(getIdsAndData);
        setData(data);
      },
    );

    return unsubscribe;
  }

  return undefined;
};

const shouldAddDiscipline = (data, discipline) =>
  !Boolean(data?.find(({ id }) => id === discipline)?.shouldShow);

const initialiseMasteryData = async (customUid, discipline, skills) => {
  const args = ['users', customUid, 'disciplines', discipline];
  await setDoc(doc(firestore, ...args), {
    shouldShow: true,
    skills,
  });
};

const showData = async (data, customUid, discipline) => {
  const disciplineData = data?.find(({ id }) => id === discipline);
  const args = ['users', customUid, 'disciplines', discipline];
  const docRef = doc(firestore, ...args);
  await updateDoc(docRef, { ...disciplineData, shouldShow: true });
};

const handleAddDiscipline = async (
  data,
  customUid,
  discipline,
  initialSkills,
) => {
  const alreadyExists =
    data?.find(({ id }) => id === discipline)?.shouldShow === false;

  alreadyExists
    ? await showData(data, customUid, discipline)
    : await initialiseMasteryData(customUid, discipline, initialSkills);
};

function App() {
  const [user, setUser] = useState(null);
  const [data, setData] = useState();
  const customUid = getCustomUid(user, user?.displayName);

  // set user after page reload
  useEffect(() => {
    auth.onAuthStateChanged((changedUser) =>
      setUser(changedUser.auth.currentUser),
    );
  }, []);

  // subscribe to database - get data and updates
  useEffect(() => {
    const unsubscribeFunc = dataSubscription(setData, customUid);
    const noOp = () => {};

    return unsubscribeFunc || noOp;
  }, [user, customUid]);

  return (
    <AppContainer>
      <Header />
      {!user ? (
        <SignInOrSignUp setUser={setUser} />
      ) : (
        <>
          <CapitalisedHeading>Hi {user.displayName}</CapitalisedHeading>
          <MasteryGrid data={data} customUid={customUid} />
          <Stack spacing={3}>
            {shouldAddDiscipline(data, 'ski') && (
              <Button
                type="button"
                fullWidth
                variant="text"
                sx={{ mt: 3, mb: 2 }}
                onClick={() =>
                  handleAddDiscipline(data, customUid, 'ski', initialSkiData)
                }
              >
                Add Ski Mastery
              </Button>
            )}
            {shouldAddDiscipline(data, 'snowboard') && (
              <Button
                type="button"
                fullWidth
                variant="text"
                sx={{ mt: 3, mb: 2 }}
                onClick={() =>
                  handleAddDiscipline(
                    data,
                    customUid,
                    'snowboard',
                    initialSnowboardData,
                  )
                }
              >
                Add Snowboard Mastery
              </Button>
            )}
          </Stack>
          <SignOut setUser={setUser} />
        </>
      )}
      <Footer />
    </AppContainer>
  );
}

export default App;
