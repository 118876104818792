import styled from '@emotion/styled';
import { FOOTER_HEIGHT, CROSS_RED } from '../constants';

const FooterContainer = styled.footer`
  position: absolute;
  width: 100%;
  bottom: 0;
  height: ${FOOTER_HEIGHT};
  display: flex;
  align-items: end;
  justify-content: center;
  background-color: ${CROSS_RED};
  border-top: 7px rgb(242, 102, 143) solid;
  color: white;
`;

const Link = styled.a`
  text-decoration: none;
  color: white;
`;

const Footer = () => (
  <FooterContainer>
    Contact:{' '}
    <Link href="mailto:info@cross-snowsports.org">
      info@cross-snowsports.org
    </Link>
  </FooterContainer>
);

export default Footer;
