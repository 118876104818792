import { getInitialData } from '../utilities';

export const snowboardSkills = {
  steering: [
    {
      skill: 'Foot Steering',
      a: 'Understand and use of foot steering',
      b: 'Foot steering with loose front binding',
      c: 'Foot steering with loose back binding',
      d: 'Foot steering with both bindings loose',
    },
    {
      skill: 'Front Leg Steering',
      a: 'To steer your board only with your front foot',
      b: 'To steer the board with only your front knee',
      c: 'To steer your board with only your front hip',
      d: 'To steer your board using your front foot, knee and hip together',
    },
    {
      skill: 'Back Leg Steering',
      a: 'To steer your board only with your back foot',
      b: 'To steer the board with only your back knee',
      c: 'To steer your board with only your back hip',
      d: 'To steer your board using your back foot, knee and hip together',
    },
    {
      skill: 'Upper Body Steering',
      a: 'To steer your board only with your Upper Body',
      b: 'To increase the strength of your Upper Body to Slow the initiation of your turn',
      c: 'To decrease the strength of your Upper Body to Slow the initiation of your turn',
      d: 'To use Upper Body Steering in Moguls',
    },
    {
      skill: 'All Steering Elements',
      a: 'To steer your board with ALL the Steering Elements from Phases 1-4',
      b: 'To steer your board with ALL the Steering Elements from Phases 1-4, in Powder',
      c: 'To steer your board with ALL the Steering Elements from Phases 1-4, in Variable terrain',
      d: 'To steer your board with ALL the Steering Elements from Phases 1-4, riding Switch',
    },
  ],
  pressure: [
    {
      skill: 'Ollies',
      a: 'Basic Ollie',
      b: 'Ollie Moving Across the Fall-Line',
      c: 'Ollie Moving Down the Fall-Line',
      d: 'Ollie Over Obstacles',
    },
    {
      skill: 'Nollies',
      a: 'Basic Nollie',
      b: 'Moving Nollie Across the Fall-Line',
      c: 'Moving Nollie Down the Fall-Line',
      d: 'Nollie Over Obstacles',
    },
    {
      skill: 'Switch Ollies',
      a: 'Basic Switch Ollie',
      b: 'Moving Switch Ollie Across the Fall-Line',
      c: 'Moving Switch Ollie Down the Fall-Line',
      d: 'Switch Ollie Over Obstacles',
    },
    {
      skill: 'Switch Nollies',
      a: 'Basic Switch Nollie',
      b: 'Moving Switch Nollie Across the Fall-Line',
      c: 'Moving Switch Nollie Down the Fall-Line',
      d: 'Switch Nollie Over Obstacles',
    },
    {
      skill: 'Dolphin Ollies',
      a: 'Basic Dolphin Ollie',
      b: 'Moving Dolphin Ollie Across the Fall-Line',
      c: 'Moving Dolphin Ollie Down the Fall-Line',
      d: 'Dolphine Ollie Over Obstacles',
    },
  ],
  edging: [
    {
      skill: 'Daffy',
      a: 'Daffy Forwards on a Flat Slope',
      b: 'Daffy Backwards on a Flat Slope',
      c: 'Daffy Forward/Backwards on a Hill with Toe Edge',
      d: 'Daffy Forward/Backwards on a Hill with Heel Edge',
    },
    {
      skill: `'C' Carving`,
      a: "Toe Edge Clean 'C' Carves",
      b: "Heel Edge Clean 'C' Carves",
      c: "Link Toe & Heel Edge 'C' Carves",
      d: "Linked 'C' Carves Using Flexion and Extension",
    },
    {
      skill: 'Cross Under',
      a: 'Cross-Under Turns Skidded',
      b: 'Cross-Under Turns With Ski Pole',
      c: 'Cross-Under Turns with Flexion and Extension',
      d: 'Cleanly Carved Cross-Under-Turns',
    },
    {
      skill: 'Toe-2-Toe',
      a: 'T2T Skidded Turns',
      b: 'T2T Skidded Turns with Jumps',
      c: 'T2T Nose Roles with Jumps and Carves',
      d: 'T2T Clean Carves',
    },
    {
      skill: 'Heel-2-Heel',
      a: 'H2H Skidded Turns',
      b: 'H2H Skidded Turns with Jumps',
      c: 'H2H Nose Roles with Jumps and Carves',
      d: 'H2H Clean Carves',
    },
  ],
  rotary: [
    {
      skill: 'Tic-Tac',
      a: 'Tic-Tac Forwards on a Flat Slope',
      b: 'Tic-Tack in a Circle on Toes',
      c: 'Tic-Tac in a Circle on Heels',
      d: 'Tic-Tac Switch on a Flat Slope',
    },
    {
      skill: '90° of Separation',
      a: '90° of Separation',
      b: 'Balanced Ski Pole on Arms with 90° of Separation',
      c: '90° of Separation with Nose Rolls',
      d: 'Switch 90° of Separation',
    },
    {
      skill: `180's Rotation`,
      a: '180 Spin on Flat',
      b: 'Moving 180 Spin Across Fall-Line off Toes',
      c: 'Moving 180 Spin Across Fall-Line off Heels',
      d: 'Moving 180 Spin Down Fall-Line',
    },
    {
      skill: 'Dolphin Turns',
      a: '180 Spin on Flat',
      b: 'Moving 180 Spin Across Fall-Line off Toes',
      c: 'Moving 180 Spin Across Fall-Line off Heels',
      d: 'Moving 180 Spin Down Fall-Line',
    },
    {
      skill: `180's Counter-Rotation`,
      a: '180 Spin on Flat',
      b: 'Moving 180 Spin Across Fall-Line off Toes',
      c: 'Moving 180 Spin Across Fall-Line off Heels',
      d: 'Moving 180 Spin Down Fall-Line',
    },
  ],
  balance: [
    {
      skill: 'Stationary Toes',
      a: 'Stationary Balancing on your Toe Edge',
      b: 'Balancing whilst Flexing Up & Down',
      c: 'Balancing with Ollie',
      d: 'Balancing with 180 Spin',
    },
    {
      skill: 'Stationary Heels',
      a: 'Stationary Balancing on your Heel Edge',
      b: 'Balancing whilst Flexing Up & Down',
      c: 'Balancing with Ollie',
      d: 'Balancing with 180 Spin',
    },
    {
      skill: 'Toe Hops',
      a: 'Stationary Toe Edge Jumps',
      b: "Toe Edge 'Jump & Stick' Up the Slope",
      c: "Toe Edge 'Jump & Stick' Down the Slope",
      d: "Single Large 'Jump & Stick' Up/Down Slope",
    },
    {
      skill: 'Heel Hops',
      a: 'Stationary Toe Edge Jumps',
      b: "Toe Edge 'Jump & Stick' Up the Slope",
      c: "Toe Edge 'Jump & Stick' Down the Slope",
      d: "Single Large 'Jump & Stick' Up/Down Slope",
    },
    {
      skill: 'Moguls',
      a: 'Linked Turns Across the Mogul Field``',
      b: 'Direct Line Down the Mogul Field',
      c: 'Jumping Between Moguls',
      d: 'Switch Moguls',
    },
  ],
};

export const initialSnowboardData = getInitialData(snowboardSkills);
