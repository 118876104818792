import { initializeApp } from 'firebase/app';
import {
  getFirestore,
  collection,
  doc,
  updateDoc,
  onSnapshot,
  setDoc,
} from 'firebase/firestore';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
  sendPasswordResetEmail,
} from 'firebase/auth';

const config = {
  apiKey: 'AIzaSyAIW3IZbTYNYpAj4qc7XzCkzsatcA2J0cs',
  authDomain: 'cs-mastery-test.firebaseapp.com',
  projectId: 'cs-mastery-test',
  storageBucket: 'cs-mastery-test.appspot.com',
  messagingSenderId: '347894173415',
  appId: '1:347894173415:web:44aa36b1d56fd0688edc42',
  measurementId: 'G-CN1HXK3M13',
};

const firebaseApp = initializeApp(config);

const firestore = getFirestore(firebaseApp);

const auth = getAuth();

export {
  firestore,
  collection,
  doc,
  updateDoc,
  onSnapshot,
  setDoc,
  auth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
  sendPasswordResetEmail,
};

// window.firebase = firebase; // this is only to play with firebase in the browser

export default firebaseApp;
