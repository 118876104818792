import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import Add from '@mui/icons-material/Add';
import Save from '@mui/icons-material/Save';
import { deepCopy } from '../utilities';

/*
  BUGS
  having multiple unsaved notes won't all save on modal close. Each note doesn't have the other unsaved notes state, merely a copy of what it used to be, so only the last note will get updated state (overriding the other note saves)
*/

const editNote = (notesState, setNotesState, id) => {
  const noteIndex = notesState.findIndex((note) => note.id === id);
  const newState = notesState.map((note, i) =>
    i === noteIndex ? { ...note, status: 'edit' } : note,
  );

  setNotesState(newState);
};

const saveNote = async (
  notesState,
  setNotesState,
  textState,
  id,
  updateDisciplineData,
  hasTextChanged,
) => {
  const noteIndex = notesState.findIndex((note) => note.id === id);
  const updatedMasteryNotes = notesState.map((note, i) =>
    i === noteIndex ? { ...note, status: 'saved', text: textState } : note,
  );

  setNotesState(updatedMasteryNotes);

  if (hasTextChanged) {
    const getDataWithUpdatedNote = ({ data, modalData }) => {
      const { id: disciplineId, generalSkill, phase } = modalData;

      const disciplineData = data?.find(
        (discipline) => discipline.id === disciplineId,
      );
      const disciplineCopy = deepCopy(disciplineData);
      disciplineCopy.skills.find(({ skill }) => skill === generalSkill).phases[
        phase
      ].notes = updatedMasteryNotes;

      return disciplineCopy;
    };

    await updateDisciplineData(getDataWithUpdatedNote);
  }
};

const deleteNote = async (
  notesState,
  setNotesState,
  text,
  id,
  updateDisciplineData,
) => {
  const deleteMessage = 'This note will be permanently deleted. Continue?';
  if (!text || window.confirm(deleteMessage)) {
    const getDataWithDeletedNote = ({ data, modalData }) => {
      const { id: disciplineId, generalSkill, phase } = modalData;

      const updatedMasteryNotes = notesState.filter((note) => note.id !== id);

      const disciplineData = data?.find(
        (discipline) => discipline.id === disciplineId,
      );
      const disciplineCopy = deepCopy(disciplineData);
      disciplineCopy.skills.find(({ skill }) => skill === generalSkill).phases[
        phase
      ].notes = updatedMasteryNotes;

      setNotesState(updatedMasteryNotes);
      return disciplineCopy;
    };

    await updateDisciplineData(getDataWithDeletedNote);
  }
};

const Note = ({
  date,
  id,
  text,
  status,
  notesState,
  setNotesState,
  updateDisciplineData,
  isModalOpen,
}) => {
  const [textState, setTextState] = useState(text);

  const hasTextChanged = () => text !== textState;

  // Save unsaved notes when the modal is closed
  useEffect(() => {
    return () => {
      if (
        status === 'edit' &&
        // eslint-disable-next-line react-hooks/exhaustive-deps
        isModalOpen.current === false &&
        textState.length
      ) {
        saveNote(
          notesState,
          setNotesState,
          textState,
          id,
          updateDisciplineData,
          hasTextChanged(),
        );
      }
    };
  });

  return (
    <Card
      sx={{
        width: '100%',
        '&:last-child': {
          marginBottom: '16px',
        },
      }}
    >
      <CardHeader
        sx={{
          padding: '0 16px',
        }}
        action={
          <CardActions disableSpacing>
            {status === 'edit' ? (
              <IconButton
                aria-label="save"
                onClick={() =>
                  saveNote(
                    notesState,
                    setNotesState,
                    textState,
                    id,
                    updateDisciplineData,
                    hasTextChanged(),
                  )
                }
              >
                <Save />
              </IconButton>
            ) : (
              <IconButton
                aria-label="edit"
                onClick={() => editNote(notesState, setNotesState, id)}
              >
                <Edit />
              </IconButton>
            )}
            <IconButton
              aria-label="delete"
              onClick={() =>
                deleteNote(
                  notesState,
                  setNotesState,
                  textState,
                  id,
                  updateDisciplineData,
                )
              }
            >
              <Delete />
            </IconButton>
          </CardActions>
        }
        subheader={date}
      />
      <CardContent
        sx={{
          padding: '0 16px 16px',
          '&:last-child': {
            paddingBottom: '16px',
          },
        }}
      >
        <Box
          component="form"
          sx={{
            width: '100%',
          }}
          noValidate
          autoComplete="off"
        >
          {status === 'edit' ? (
            <TextField
              sx={{
                width: '100%',
              }}
              id="outlined-multiline-flexible"
              placeholder="Enter note here"
              multiline
              maxRows={4}
              value={textState}
              onChange={(e) => setTextState(e.target.value)}
              autoFocus={status === 'edit'}
            />
          ) : (
            <Typography
              variant="body1"
              component="div"
              sx={{
                whiteSpace: 'pre-wrap',
              }}
            >
              {textState}
            </Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

const NotesHeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px grey solid;
`;

const getCurrentDate = () =>
  new Date().toLocaleDateString('en-gb', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

const addNewNote = (existingNotes, setNotesState) =>
  setNotesState([
    {
      date: getCurrentDate(),
      id: new Date().getTime(),
      text: '',
      status: 'edit',
    },
    ...existingNotes,
  ]);

const NotesHeading = ({ notesState, setNotesState }) => (
  <NotesHeadingContainer>
    <Typography variant="h6" component="div">
      Notes:
    </Typography>
    <IconButton
      aria-label="add new note"
      onClick={() => addNewNote(notesState, setNotesState)}
    >
      <Add />
    </IconButton>
  </NotesHeadingContainer>
);

const Notes = ({ notes, updateDisciplineData, isModalOpen }) => {
  const [notesState, setNotesState] = useState(notes);

  return (
    <>
      <NotesHeading notesState={notesState} setNotesState={setNotesState} />
      <Stack spacing={2} direction="column" justifyContent="stretch">
        {notesState.map((note) => (
          <Note
            {...note}
            key={note.id}
            notesState={notesState}
            setNotesState={setNotesState}
            updateDisciplineData={updateDisciplineData}
            isModalOpen={isModalOpen}
          />
        ))}
      </Stack>
    </>
  );
};

export default Notes;
