import styled from '@emotion/styled';
import { CROSS_RED } from '../constants';

const DIVIDER_WIDTH = 54;

const HeaderContainer = styled.header`
  color: ${CROSS_RED};
  position: relative;

  &:before {
    content: '';
    position: absolute;
    height: 3px;
    width: ${DIVIDER_WIDTH}px;
    bottom: 0;
    left: calc(50% - ${DIVIDER_WIDTH / 2}px);
    background-color: ${CROSS_RED};
  }
`;

const Title = styled.h1`
  margin: 0;
  font-weight: 550;
  @media screen and (min-width: 600px) {
    font-size: calc(3rem + 0.5vw);
  }
  font-size: calc(2rem + 1.5vw);
`;

const Header = () => (
  <HeaderContainer>
    <Title>Cross Snowsports UK</Title>
  </HeaderContainer>
);

export default Header;
