import styled from '@emotion/styled';
import { FOOTER_HEIGHT } from '../constants';

const AppContainer = styled.div`
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: left;
  min-height: 100vh;
  padding-bottom: ${FOOTER_HEIGHT};
`;

export default AppContainer;
