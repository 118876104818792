import { getInitialData } from '../utilities';

export const skiSkills = {
  steering: [
    {
      skill: 'Foot Steering',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
    {
      skill: 'Lower Body Steering',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
    {
      skill: 'Upper Body Steering',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
    {
      skill: 'Inside Leg Steering',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
    {
      skill: 'All Steering Elements',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
  ],
  pressure: [
    {
      skill: 'Ollies',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
    {
      skill: 'Nollies',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
    {
      skill: 'Switch Ollies',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
    {
      skill: 'Switch Nollies',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
    {
      skill: 'Dolphin Ollies',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
  ],
  edging: [
    {
      skill: 'Skating',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
    {
      skill: `'C' Carving`,
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
    {
      skill: 'Cross Under',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
    {
      skill: `Switch 'C' Carving`,
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
    {
      skill: 'Switch Cross Under',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
  ],
  rotary: [
    {
      skill: 'Straight Run with Edge Slip',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
    {
      skill: 'Pivot Slips',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
    {
      skill: 'Straight Run to Edge Slip',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
    {
      skill: 'Pivot Turns',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
    {
      skill: 'Dolphin Turns',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
  ],
  balance: [
    {
      skill: 'Traverse with Lifted Up-hill Ski',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
    {
      skill: 'Traverse With Lifted Down-hill Ski',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
    {
      skill: 'Up-hill Ski Hops',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
    {
      skill: 'Down-hill Ski Hops',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
    {
      skill: 'Moguls w/o Poles',
      a: 'Exercise a',
      b: 'Exercise b',
      c: 'Exercise c',
      d: 'Exercise d',
    },
  ],
};

export const initialSkiData = getInitialData(skiSkills);
