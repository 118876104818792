export const getIdsAndData = (snapshot) => ({
  id: snapshot.id,
  ...snapshot.data(),
});

export const getInitialData = (disciplineSkills) => {
  return Object.entries(disciplineSkills).map(([skill, phases]) => ({
    skill,
    phases: phases.map(() => ({
      notes: [],
      mastery: 0,
    })),
  }));
};

export const sanitise = (input) => input?.replace(/[^a-zA-Z0-9- ]/g, '');

export const getCustomUid = (user, displayName) =>
  user ? `${user.uid}-${displayName?.replace(' ', '_').slice(0, 10)}` : null;

export const deepCopy = (data) => JSON.parse(JSON.stringify(data));
