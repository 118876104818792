import React, { useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import CapitalisedHeading from './CapitalisedHeading';
import Notes from './Notes';
import { skiSkills, snowboardSkills } from '../static-data';
import { firestore, doc, updateDoc } from '../firebase';
import { deepCopy } from '../utilities';

const GAP = { rem: 0.5, px: 8 };
const CELL_WIDTH = 200;

/*
Todo:
  Mobile styles
  - tighten up sizes and spacing - smaller sizes at mobile? Less gap between headlines. Maybe buttons left and right for carousel view
  User input sanitisation (sign in form, sign up, notes)
  Add footer, have it at the bottom of the page always 
  Get page title right, it's now huge for desktop at big screens
  Background image on the page
*/

const HeaderWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 auto;
`;

const IconWrapper = styled.div`
  position: absolute;
  display: flex;
  opacity: 0.5;
  right: -2.5rem;
  padding: 0.5rem;
  cursor: pointer;
`;

const Heading = ({ id, discipline, customUid }) => {
  return (
    <HeaderWrapper>
      <CapitalisedHeading>{`${id} Mastery Guide`}</CapitalisedHeading>
      <IconWrapper
        onClick={async () => {
          const args = ['users', customUid, 'disciplines', id];
          const docRef = doc(firestore, ...args);
          await updateDoc(docRef, { ...discipline, shouldShow: false });
        }}
      >
        <DeleteOutline />
      </IconWrapper>
    </HeaderWrapper>
  );
};

const Skill = ({ phase, children }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      bgcolor: `${theme[phase].main}`,
      '&:hover': {
        bgcolor: `${theme[phase].dark}`,
      },
    }}
  >
    {children}
  </Box>
);

const Level = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-weight: 700;
  cursor: pointer;
  border: 0.01rem solid;
  ${({ theme, phase }) => `border-color: ${theme[phase].dark}`};
  ${({ theme, phase, mastered }) =>
    mastered && `background-color: ${theme[phase].light}`};
`;

const CellContainer = ({ phase, children }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: CELL_WIDTH,
      height: 100,
      borderRadius: 1,
      overflow: 'hidden',
      border: `1px solid`,
      borderColor: phase ? `${theme[phase].dark}` : 'common.black',
      'scroll-snap-align': 'start',
      'transform-origin': 'center center',
      transform: 'scale(1)',
      transition: 'transform 0.5s',
      position: 'relative',
    }}
  >
    {children}
  </Box>
);

const Cell = ({ skill, mastery, setModal, phase }) => (
  <CellContainer phase={phase}>
    <Stack
      onClick={setModal}
      direction="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
    >
      <Skill phase={phase}>{skill}</Skill>
      <Stack
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        height="100%"
        width="100%"
      >
        <Level phase={phase} mastered={mastery >= 1}>
          A
        </Level>
        <Level phase={phase} mastered={mastery >= 2}>
          B
        </Level>
        <Level phase={phase} mastered={mastery >= 3}>
          C
        </Level>
        <Level phase={phase} mastered={mastery >= 4}>
          D
        </Level>
      </Stack>
    </Stack>
  </CellContainer>
);

const getDisciplineUpdater =
  (customUid, disciplineId, data, modalData) => async (callback) => {
    const args = ['users', customUid, 'disciplines', disciplineId];
    const docRef = doc(firestore, ...args);

    await updateDoc(docRef, callback({ data, modalData }));
  };

const updateMasteryScore = async (newMasteryValue, updateDisciplineData) => {
  const getDataWithUpdatedMastery = ({ data, modalData }) => {
    const { id, generalSkill, phase } = modalData;

    const disciplineData = data?.find((discipline) => discipline.id === id);
    const disciplineCopy = deepCopy(disciplineData);
    disciplineCopy.skills.find(({ skill }) => skill === generalSkill).phases[
      phase
    ].mastery = newMasteryValue;

    return disciplineCopy;
  };

  await updateDisciplineData(getDataWithUpdatedMastery);
};

const closeAndUpdate = (
  closeModal,
  modalData,
  selectedValue,
  updateDisciplineData,
  isModalOpen,
) => {
  if (modalData.mastery !== selectedValue) {
    updateMasteryScore(selectedValue, updateDisciplineData);
  }
  isModalOpen.current = false;
  closeModal();
};

const disableBodyScrolling = () => (document.body.style.overflow = 'hidden');
const enableBodyScrolling = () => (document.body.style.overflow = 'unset');

const Modal = ({ modalData, closeModal, data, customUid }) => {
  const [selectedValue, setSelectedValue] = useState(modalData.mastery);
  const modalRef = useRef(null);
  const isModalOpen = useRef(true);
  const updateDisciplineData = getDisciplineUpdater(
    customUid,
    modalData.id,
    data,
    modalData,
  );

  // Close modal when clicking off modal
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (!modalRef?.current.contains(e.target)) {
        closeAndUpdate(
          closeModal,
          modalData,
          selectedValue,
          updateDisciplineData,
          isModalOpen,
        );
      }
    };

    disableBodyScrolling();
    document.addEventListener('mousedown', handleOutsideClick); // need to make sure this event works on all devices
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
      enableBodyScrolling();
    };
  });

  const onChange = (event) => setSelectedValue(parseInt(event.target.value));

  return createPortal(
    <Box
      ref={modalRef}
      sx={{
        position: 'fixed',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        padding: '1rem',
        borderRadius: '1rem',
        overflowY: 'auto',
        border: `1px solid`,
        borderColor: 'primary.main',
        backgroundColor: 'background.default',
        width: 'calc(100vw - 4rem)',
        maxWidth: '600px',
        maxHeight: '80vh',
      }}
    >
      <FormControl onChange={onChange} sx={{ width: '100%' }}>
        <FormLabel
          id="mastery-radio-buttons"
          sx={{
            alignSelf: 'center',
            paddingBottom: '0.5rem',
          }}
        >
          <Typography variant="h5" component="div">
            {modalData.skill}
          </Typography>
        </FormLabel>
        <RadioGroup
          aria-labelledby="mastery-radio-buttons"
          name="radio-buttons-group"
        >
          <FormControlLabel
            value="1"
            checked={selectedValue === 1}
            control={<Radio />}
            label={`A - ${modalData.a}`}
          />
          <FormControlLabel
            value="2"
            checked={selectedValue === 2}
            control={<Radio />}
            label={`B - ${modalData.b}`}
          />
          <FormControlLabel
            value="3"
            checked={selectedValue === 3}
            control={<Radio />}
            label={`C - ${modalData.c}`}
          />
          <FormControlLabel
            value="4"
            checked={selectedValue === 4}
            control={<Radio />}
            label={`D - ${modalData.d}`}
          />
          <FormControlLabel
            value="0"
            checked={selectedValue === 0}
            control={<Radio />}
            label={`Not started`}
          />
        </RadioGroup>
      </FormControl>
      <Notes
        notes={modalData.notes}
        updateDisciplineData={updateDisciplineData}
        isModalOpen={isModalOpen}
      />
      <Button
        onClick={() =>
          closeAndUpdate(
            closeModal,
            modalData,
            selectedValue,
            updateDisciplineData,
            isModalOpen,
          )
        }
        variant="outlined"
      >
        Close
      </Button>
    </Box>,
    document.body,
  );
};

const GridWrapper = styled.div`
  overflow-x: scroll;
  position: relative;
  justify-content: initial;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  scroll-padding-left: ${GAP.rem * 2}rem;
  -webkit-overflow-scrolling: touch;
  -webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  -webkit-scrollbar-thumb {
    background: black;
    border-radius: 10px;
  }
  -webkit-scrollbar-track {
    background: transparent;
  }
`;

const Grid = styled.div`
  display: grid;
  gap: ${GAP.rem}rem;
  width: fit-content;
  @media (min-width: ${6 * CELL_WIDTH + 7 * GAP.px}px) {
    width: 100%;
  }
  margin: 0 ${GAP.rem}rem;
  justify-content: center;

  ${({ columns }) =>
    `grid-template-columns: repeat(${columns || 6}, ${CELL_WIDTH}px);`}
  ${({ rows }) =>
    `grid-template-rows: repeat(${rows || 5}, minmax(3rem, 1fr));`}
`;

const SkillHeading = styled.div`
  font-weight: 700;
  text-transform: capitalize;
`;

const skills = {
  ski: skiSkills,
  snowboard: snowboardSkills,
};

const getGridRow = (id, skill, phases, setModalData) => {
  const cells = phases.map((phase, index) => {
    /**
       * modalData:
        {
          id: ski,
          generalSkill: balance,
          phase: 0, // index
          skill: 'Foot Steering',
          a: 'Exercise a',
          b: 'Exercise b',
          c: 'Exercise c',
          d: 'Exercise d',
          mastery: 1,
          notes: []
        }
       */

    const initialData = skills[id][skill][index];
    const modalData = {
      id,
      generalSkill: skill,
      phase: index,
      ...initialData,
      ...phase,
    };

    return (
      <Cell
        key={`${id}-${skill}-${index}`}
        skill={modalData.skill}
        mastery={modalData.mastery}
        setModal={() => setModalData(modalData)}
        phase={`phase${index + 1}`}
      />
    );
  }); // might need key props to stop react warnings

  return [
    <CellContainer key={`${id}-${skill}-row`}>
      <SkillHeading>{skill}</SkillHeading>
    </CellContainer>,
  ].concat(cells);
};

const SkillsGrid = ({ skills, id, setModalData }) => (
  <GridWrapper>
    <Grid>
      {skills.map(({ skill, phases }) =>
        getGridRow(id, skill, phases, setModalData),
      )}
    </Grid>
  </GridWrapper>
);

const theme = createTheme({
  phase1: {
    main: '#d58bdf',
    light: '#e3b5ea',
    dark: '#c464d3',
  },
  phase2: {
    main: '#81dada',
    light: '#a9e6e6',
    dark: '#59cfcf',
  },
  phase3: {
    main: '#8adc7f',
    light: '#ade7a5',
    dark: '#64d256',
  },
  phase4: {
    main: '#e1e495',
    light: '#edefbf',
    dark: '#d7da6c',
  },
  phase5: {
    main: '#ddb29d',
    light: '#ebcfc3',
    dark: '#d19175',
  },
});

const MasteryGrid = ({ data, customUid }) => {
  const [modalData, setModalData] = useState(false);

  const closeModal = () => setModalData(false);

  return (
    <ThemeProvider theme={theme}>
      {data?.map((discipline) => {
        const { id, skills, shouldShow } = discipline;
        if (!shouldShow) return null;

        return (
          <Stack
            key={`${id}-grid`}
            sx={{
              '-webkit-scrollbar-thumb': {
                background: 'black',
              },
              '-webkit-scrollbar-track': {
                background: 'transparent',
              },
            }}
          >
            <Heading id={id} discipline={discipline} customUid={customUid} />
            <SkillsGrid skills={skills} id={id} setModalData={setModalData} />
          </Stack>
        );
      })}
      {modalData && (
        <Modal
          modalData={modalData}
          closeModal={closeModal}
          data={data}
          customUid={customUid}
        />
      )}
    </ThemeProvider>
  );
};

export default MasteryGrid;
